<template>
  <div class="page-system-permission">
    <div class="left-box">
      <div class="search">
        <el-input v-model="roleName" clearable placeholder="搜索角色" @input="searchHandle">
          <i slot="suffix" class="el-input__icon el-icon-search cursor" />
        </el-input>
      </div>
      <div class="permission-list">
        <div v-for="(item, index) in roleList" v-show="item.show" :key="index" :class="['item', { active: item.active}]" @click="toggleHandle(index)">{{ item.name }}</div>
      </div>
    </div>
    <div class="right-box">
      <div class="search">
        <el-input v-model="filterMenu" clearable placeholder="搜索菜单" @input="filterHandle">
          <i slot="suffix" class="el-input__icon el-icon-search cursor" />
        </el-input>
      </div>
      <div class="tree-content">
        <el-tree ref="MenuTree" :data="menuList" default-expand-all :expand-on-click-node="false" :filter-node-method="filterNodeHandle" />
      </div>
    </div>
  </div>
</template>

<script>
import MENU_LIST_DATA from '@/store/modules/menuList.js';

export default {
  name: 'SystemPermission',
  data() {
    return {
      roleName: '',
      filterMenu: '',
      ROLE_LIST: [
        { id: 1, name: '超级管理员', active: false, show: true, codes: ['10', '20', '21', '22', '30', '31', '32', '33', '34', '35', '36', '37', '38', '40', '41', '42', '43', '44', '50', '51', '52', '53', '60', '61', '62', '70', '71', '72', '73', '74'] },
        { id: 2, name: '前线', active: false, show: true, codes: ['10', '20', '21', '22', '30', '31', '32', '33', '40', '41', '43', '50', '52', '60', '62'] },
        { id: 3, name: '小五', active: false, show: true, codes: ['10', '20', '21', '22', '30', '34', '35', '36', '37', '38', '40', '41', '44', '50', '51', '52'] },
        { id: 4, name: '运营', active: false, show: true, codes: ['10', '20', '21', '40', '41', '42', '50', '51', '52', '60', '61', '62', '70', '71', '72', '73', '74'] },
        // { id: 5, name: '财务', active: false, show: true, codes: ['7', '13', '14', '15'] },
      ],
      roleList: [],
      menuList: [],
    };
  },
  created() {
    this.roleList = JSON.parse(JSON.stringify(this.ROLE_LIST));
    this.choseRoleHandle(0);
  },
  methods: {
    // 选择权限
    choseRoleHandle(index) {
      const roleData = this.roleList[index];
      const { codes } = roleData;
      roleData.active = true;
      const build = [];
      MENU_LIST_DATA.forEach((item) => {
        if (codes.includes(item.code)) {
          const itemData = {
            label: item.title,
            children: [],
          };
          if (item.children && item.children.length > 0) {
            item.children.forEach((val) => {
              if (codes.includes(val.code)) {
                itemData.children.push({
                  label: val.title,
                });
              }
            });
          }
          build.push(itemData);
        }
      });
      this.menuList = build;
    },
    // 切换角色
    toggleHandle(index) {
      this.roleList.forEach((item, i) => {
        if (index === i) {
          this.choseRoleHandle(index);
        } else {
          item.active = false;
        }
      });
    },
    // 搜索角色
    searchHandle() {
      this.roleList.forEach((item) => {
        if (item.name.indexOf(this.roleName) > -1) {
          item.show = true;
        } else {
          item.show = false;
        }
      });
    },
    // 筛选菜单
    filterHandle() {
      this.$refs.MenuTree.filter(this.filterMenu);
    },
    // 筛选菜单节点
    filterNodeHandle(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },
};

</script>

<style lang="scss" scoped>
.page-system-permission {
  display: flex;
  width: 100%;
  height: 100%;
  .left-box {
    width: 300px;
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    .search {
      margin-bottom: 30px;
    }
    .permission-list {
      flex: 1;
      overflow-y: auto;
      .item {
        height: 48px;
        line-height: 48px;
        padding: 0 15px;
        border-radius: 4px;
        cursor: pointer;
        &.active {
          background-color: #DFEAFF;
        }
      }
    }
  }
  .right-box {
    flex: 1;
    background-color: #fff;
    border-left: solid 1px #EDF0F5;
    padding: 15px;
    display: flex;
    flex-direction: column;
    .search {
      width: 300px;
      margin-bottom: 30px;
    }
    .tree-content {
      flex: 1;
      padding-right: 50px;
      overflow-y: auto;
    }
  }
}
</style>

<style lang="scss">
.page-system-permission {
  .right-box {
    .el-checkbox__input {
      vertical-align: bottom;
    }
  }
}
</style>
